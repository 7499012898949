.pop{
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: .6);
    left: 0px;
    z-index: 201;
}
.closePOp {
    position: absolute;
    right: 14%;
    font-size: 30px;
    top: 14%;
    z-index: 1;
    border-radius: 50%;
    background-color: #fff;
    width: 40px;
    height: 40px;
}
.pop>.card.horizontal {
    max-height: 350px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 10px 10px 15px 0px;
}