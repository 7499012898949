.redes{
    height: 60px;
}
.link-redes-foter{
    margin-left: 11px;
    margin-right: 11px;
    img{
        width: 23px;
        height: 23px;
    }
}
.page-footer ul li a, .page-footer p {
    color: #aaa;
    font-size: .8rem;
    font-weight: 300;
    margin: 5px 0;
}
.foter-title{
    font-size: .9rem;
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
    .link-redes-foter>img{
        width: 40px;
        height: 40px;
    }   
}