$grey: #fff;
$blue: rgba(0, 0, 255, .5);

@keyframes rotate2 {
	0% {
		transform: rotate(0deg);
		border-top-color: $grey;
	}
	50% {
		transform: rotate(180deg);
		border-top-color: $blue;
	}
	100% {
		transform: rotate(360deg);
		border-top-color: $grey;
	}
}
@mixin loaderDivMixin {
	border-radius: 50%;
	padding: 8px;
	border: 2px solid transparent;
	animation: rotate linear 3.5s infinite;
}
.loader {
	position: relative;
	margin: 75px auto;
	width: 150px;
	height: 150px;
	display: block;
	overflow: hidden;
	div {
		height: 100%;
	}
}
.loader4, .loader4 div {
	@include loaderDivMixin;
	border-radius: 50%;
	padding: 4px;
	animation: rotate2 4s infinite linear;
}
// div:hover {
// 	animation-play-state: paused;
// }

.loader, .loader * {
	will-change: transform;
}
.loader-bg {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100vh;
	top: 0px;
	left: 0px;
    z-index: 10;
}