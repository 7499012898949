.fondo-service{
    background: url('../../img/Compras-en-linea.jpg');
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    filter: brightness(.2);
}

.galeria, .banner-service{
    position: relative;
}
.foto {
    transform: rotate(5deg);
    position: absolute;
    height: 100%;
    top: 0%;
    left: 0%;
    width: 100%;
    z-index: -1;
}
.sombra {
    box-shadow: 0px 0px 15px 5px !important;
}
.left-plain{
    position: relative;
    // left: 10px;
    top: 40px;
}
.center-plain{
    position: relative;
    z-index: 1;
}
.right-plain{
    position: relative;
    // right: 10px;
    top: 40px;
}