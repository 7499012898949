.mySlides {display: none;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}
.img-slider {
  width: 45%;
  height: 420px;
  position: absolute;
  left: 0px;
  top: -50px;
}
.slide-img1{
  background-image: url('../../img/slide1.jpg');
}
.slide{
  width: 100%;
  height: 320px;
  background-size: cover;
  background-position: center;
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.fade:not(.show) {
  opacity: 1 !important;
}
/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 80%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  // color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: transparent !important;
  font-size: 40px;
}

/* Position the "next button" to the right */
.prev {
  left: 50%;
  border-radius: 3px 0 0 3px;
  border: none !important;
}
.next {
  right: 40%;
  border-radius: 3px 0 0 3px;
  border: none !important;
}

/* On hover, add a black background color with a little bit see-through */
// .prev:hover, .next:hover {
//   background-color: rgba(0,0,0,0.8) !important;
// }
.d-grid{
  display: grid;
}
.line-red{
  border-left: 5px solid #b72025;
  padding-left: 5px;
}
@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .6} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}
@media only screen and (max-width: 600px) {
  .slide {
    height: 100vh;
  }
  .img-slider {
    width: 100%;
    height: 209px;
    position: relative;
  }
  .prev, .next {
    top: 40%;
  }
  .prev {
    left: 35%;
  }
  .next {
    right: 35%;
  }
}